import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "alert alert-warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["qntn-form", { 'hide-formio-form': $setup.loading }]),
    ref: "formContainer"
  }, [
    ($setup.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString($setup.error), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}