export const defaultTextColor = "#212529";
export const primaryBootstrapColor = "#007bff";
export const borderBootstrapColor = "#ced4da";
export const placeholderBootstrapColor = "#6c757d";
export const fontList = [
  { text: "Arial", value: "Arial" },
  { text: "Courier", value: "Courier" },
  { text: "Georgia", value: "Georgia" },
  { text: "Tahoma", value: "Tahoma" },
  { text: "Times New Roman", value: "Times New Roman" },
  { text: "Trebuchet MS", value: "Trebuchet MS" },
  { text: "Verdana", value: "Verdana" },
];
export const fontWeightList = [
  { text: "Normal", value: "normal", class: "normal-icon" },
  { text: "Bold", value: "bold", class: "bold-icon" },
];
export const fontStyleList = [
  { text: "Normal", value: "normal", class: "normal-icon" },
  { text: "Italic", value: "italic", class: "italic-icon" },
];
export const labelPosition = [
  { text: "Top", value: "" },
  { text: "Left (Left-aligned)", value: "left-left" },
  { text: "Left (Right-aligned)", value: "left-right" },
  { text: "Right (Left-aligned)", value: "right-left" },
  { text: "Bottom", value: "bottom" },
];
export const optionsLabelPosition = [
  { text: "Top", value: "top" },
  { text: "Left", value: "left" },
  { text: "Right", value: "" },
  { text: "Bottom", value: "bottom" },
];
export const textAlignList = [
  { text: "Left", value: "left", class: "align-left" },
  { text: "Center", value: "center", class: "align-center" },
  { text: "Right", value: "right", class: "align-right" },
];

export const defaultContainerMaxWidth = "600px";

export const colorRegExp = new RegExp(
  /^#([A-Fa-f0-9]{3,4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/
);

export const externalFontLink =
  "https://quentn-form-builder.s3.eu-west-1.amazonaws.com/assets/fonts/fonts.json";

export const defaultFormGradient = "linear-gradient(#ffffff, #e0e8ec)";
