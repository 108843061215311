import { Utils } from "formiojs";
import { buttonHoverAttach } from "@/settings/componentsAttachMethods";
import tinycolor from "tinycolor2";
import { colorRegExp, defaultFormGradient } from "@/constants/general";

export const findByProperty = (
  obj: Record<string, unknown>,
  checkFunction: (checkedObj: Record<string, unknown>) => boolean
) => {
  if (checkFunction(obj)) {
    return obj;
  }
  for (const item of Object.values(obj).filter(
    (v) => v && typeof v === "object"
  )) {
    const found: unknown = findByProperty(
      item as Record<string, unknown>,
      checkFunction
    );
    if (found) {
      return found;
    }
  }
};

export const cssStringValueRegex = (property: string) => {
  return new RegExp(`^${property}:[^;]+;|;${property}:[^;]+`);
};

export const removeCssStyle = (removedAttribute = "", styleString = "") => {
  const regex = cssStringValueRegex(removedAttribute);
  return styleString?.replace(regex, "") || "";
};

export const findCssValue = (property = "", string = "") => {
  let result = "";
  const regex = cssStringValueRegex(property);
  const match = string?.match(regex);
  if (match?.length) {
    result = match[0].replace(";", "").replace(`${property}:`, "").trim();
  }
  return result;
};

export const getObjectValueByNestedKey = (object: any, nestedKey: string) => {
  const path = nestedKey.split(".");
  let currentObjectValue = object;
  path.forEach((key, index) => {
    if (!currentObjectValue.hasOwnProperty(key)) {
      currentObjectValue[key] = path.length - 1 === index ? "" : {};
    }
    currentObjectValue = currentObjectValue[key];
  });
  return currentObjectValue;
};

export const setObjectValueByNestedKey = (
  object: any,
  nestedKey: string,
  newValue: any
) => {
  const path = nestedKey.split(".");
  let currentObjectValue = object;
  path.forEach((key, index) => {
    if (path.length - 1 === index) {
      currentObjectValue[key] = newValue;
    } else {
      if (!currentObjectValue[key]) {
        currentObjectValue[key] = {};
      }
      currentObjectValue = currentObjectValue[key];
    }
  });
};

export const setAlphaColor = (color: string, alpha: number) => {
  return tinycolor(color).setAlpha(alpha).toRgbString();
};

export const getGradientWithOpacity = (schema: any) => {
  let gradient = findCssValue(
    "background",
    schema.designOptions.containerStyle
  );

  if (
    !schema.designOptions?.containerGradientStartOpacity &&
    schema.designOptions?.containerGradientStartOpacity !== 0 &&
    !schema.designOptions?.containerGradientEndOpacity &&
    schema.designOptions?.containerGradientEndOpacity !== 0
  ) {
    return schema.designOptions.containerStyle;
  }

  if (!gradient) {
    gradient = defaultFormGradient;
  }

  let startGradient = gradient
    .replace("linear-gradient(", "")
    .split(",")[0]
    .trim();
  let endGradient = gradient.split(",")[1].trim().replace(")", "");
  if (
    schema.designOptions?.containerGradientStartOpacity ||
    schema.designOptions?.containerGradientStartOpacity === 0
  ) {
    startGradient = setAlphaColor(
      startGradient,
      schema.designOptions.containerGradientStartOpacity
    );
  }
  if (
    schema.designOptions?.containerGradientEndOpacity ||
    schema.designOptions?.containerGradientEndOpacity === 0
  ) {
    endGradient = setAlphaColor(
      endGradient,
      schema.designOptions.containerGradientEndOpacity
    );
  }
  return `${removeCssStyle(
    "background",
    schema.designOptions.containerStyle
  )}background: linear-gradient(${startGradient}, ${endGradient});`;
};

export const applyContainerStyles = (
  schema: any,
  query: string | Element = "builder",
  searchBy: "id" | "element" = "id"
) => {
  const formWrapper =
    searchBy === "id"
      ? document.getElementById(query as string)
      : (query as Element);
  const formElement = formWrapper?.querySelector(".formio-form");
  if (
    schema?.designOptions?.containerStyle ||
    schema?.designOptions?.containerGradientStartOpacity ||
    schema.designOptions?.containerGradientStartOpacity === 0 ||
    schema?.designOptions?.containerGradientEndOpacity ||
    schema?.designOptions?.containerGradientEndOpacity === 0
  ) {
    (formElement as HTMLElement).style.cssText = getGradientWithOpacity(schema);
  }
};

export const getCssFromStyle = (property: string, styleString: string) => {
  const matches = styleString.match(new RegExp(`${property}:[^;]+;`));
  return matches?.length ? matches[0] : "";
};

export const applyHeadlineStyles = (
  schema: any,
  query: string | Element = "builder",
  searchBy: "id" | "element" = "id"
) => {
  const formWrapper =
    searchBy === "id"
      ? document.getElementById(query as string)
      : (query as Element);
  const headline = formWrapper?.querySelector(".form-headline");
  (headline as HTMLElement).textContent = schema?.hasOwnProperty("headlineText")
    ? schema.headlineText
    : "<Headline>";
  const maxWidth = schema?.designOptions?.containerStyle
    ? getCssFromStyle("max-width", schema.designOptions.containerStyle)
    : "";
  if (schema?.designOptions?.headline?.headlineStyle) {
    (headline as HTMLElement).style.cssText =
      schema.designOptions.headline.headlineStyle + maxWidth;
  } else if (maxWidth) {
    (headline as HTMLElement).style.cssText = maxWidth;
  }
};

export const applyButtonsStyles = (
  schema: any,
  query: string | Element = "builder",
  searchBy: "id" | "element" = "id"
) => {
  if (schema?.designOptions) {
    const formWrapper =
      searchBy === "id"
        ? document.getElementById(query as string)
        : (query as Element);
    const containerElement = formWrapper?.querySelector(
      ".formio-wizard-nav-container"
    ) as HTMLElement;
    containerElement.style.cssText = schema.designOptions?.button?.buttonAlign;
    const btnListElement = containerElement.querySelectorAll("li");
    btnListElement.forEach((el) => {
      el.style.cssText = `width: ${findCssValue(
        "width",
        schema.designOptions?.button?.buttonStyle
      )}`;
      (el.firstElementChild as HTMLElement).style.cssText =
        schema.designOptions?.button?.buttonStyle;
    });
    buttonHoverAttach(null, null, schema);
  }
};

export const applyButtonsText = (
  schema: any,
  query: string | Element = "builder",
  searchBy: "id" | "element" = "id"
) => {
  const formWrapper =
    searchBy === "id"
      ? document.getElementById(query as string)
      : (query as Element);
  const prevBtn = formWrapper?.querySelector(
    ".btn-wizard-nav-previous"
  ) as HTMLButtonElement;
  if (prevBtn) {
    prevBtn.textContent = schema?.buttonsText?.previous || "Previous";
  }

  const nextBtn = formWrapper?.querySelector(
    ".btn-wizard-nav-next"
  ) as HTMLButtonElement;
  if (nextBtn) {
    nextBtn.textContent = schema?.buttonsText?.next || "Next";
  }
  const submitBtn = formWrapper?.querySelector(
    ".btn-wizard-nav-submit"
  ) as HTMLButtonElement;
  if (submitBtn) {
    submitBtn.textContent = schema?.buttonsText?.submit || "Submit";
  }
};

const isValidPrefillValue = (component: any, defaultValue: string) => {
  if (component.type === "select" || component.type === "radio") {
    const values =
      component.type === "select" ? component.data.values : component.values;
    return values.some((item: any) => {
      if (component.originalKey === "country") {
        return item.value === defaultValue.toUpperCase();
      }

      return item.value === defaultValue;
    });
  }

  return true;
};

export const getSchemaByPrefill = (schema: any) => {
  const newSchema = JSON.parse(JSON.stringify(schema));
  const components = Utils.searchComponents(newSchema.components, {
    prefill: true,
  });
  const sanitizedQueryString = window.location.search.replace(/\+/g, "%2B");
  const urlParams = new URLSearchParams(sanitizedQueryString);
  const timestampRegExp = new RegExp(/^\d{1,10}$/);
  components.forEach((component: any) => {
    const defaultValue = urlParams.get(component.getKey);
    if (defaultValue) {
      if (isValidPrefillValue(component, defaultValue)) {
        component.defaultValue = defaultValue;

        if (component.originalKey === "country") {
          component.defaultValue = defaultValue.toUpperCase();
        }
      }

      if (component.type === "selectboxes") {
        const allDefaultValues = urlParams.getAll(component.getKey);
        component.defaultValue = {};
        component.values.forEach((item: any) => {
          component.defaultValue[item.value] = allDefaultValues.includes(
            item.value
          );
        });
      }

      if (
        component.type === "datetime" &&
        timestampRegExp.test(component.defaultValue)
      ) {
        component.defaultValue = new Date(
          Number(component.defaultValue) * 1000
        ).toISOString();
      }
    }

    if (component.defaultValue && component.hideOnPrefill) {
      component.hidden = true;
      component.clearOnHide = false;
    }
  });
  return newSchema;
};

export const replaceTag = (element: Element, newTag: string) => {
  const newElement = document.createElement(newTag);
  newElement.innerHTML = element.innerHTML;

  Array.prototype.forEach.call(element.attributes, (attr) => {
    newElement.setAttribute(attr.name, attr.value);
  });

  element.parentNode?.insertBefore(newElement, element);
  element.parentNode?.removeChild(element);
  return newElement;
};

export const objectToFormData = (
  obj: any,
  formData?: FormData,
  parentKey?: string
): FormData => {
  if (formData === undefined) {
    formData = new FormData();
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const propValue = obj[key];

      if (typeof propValue === "object" && !(propValue instanceof File)) {
        objectToFormData(propValue, formData, propName);
      } else {
        formData.append(propName, propValue);
      }
    }
  }

  return formData;
};

export const objectToUrlEncoded = (obj: any, parentKey?: string): string => {
  const formData = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propName = parentKey ? `${parentKey}[${key}]` : key;
      const propValue = obj[key];

      if (typeof propValue === "object" && !Array.isArray(propValue)) {
        formData.push(objectToUrlEncoded(propValue, propName));
      } else if (Array.isArray(propValue)) {
        for (let i = 0; i < propValue.length; i++) {
          const arrayKey = `${propName}[${i}]`;
          formData.push(
            `${encodeURIComponent(arrayKey)}=${encodeURIComponent(
              propValue[i]
            )}`
          );
        }
      } else {
        formData.push(
          `${encodeURIComponent(propName)}=${encodeURIComponent(propValue)}`
        );
      }
    }
  }

  return formData.join("&");
};

export const addFormMessage = (
  parentElement: Element,
  message: string,
  alertClass: string
) => {
  const messageElement = document.createElement("div");
  messageElement.append(message);
  messageElement.classList.add("alert", alertClass);
  parentElement.prepend(messageElement);
};

export const escapeHtml = (text: string) => {
  const replacements = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
  } as Record<string, string>;

  return text.replace(/[&<>"']/g, (match: string) => replacements[match]);
};

export const getValidColor = (color: string) => {
  return colorRegExp.test(color) ? color : "#000000";
};

export const addCssFileToDom = (cssFilePath: string) => {
  const linkElement = document.createElement("link");
  linkElement.rel = "stylesheet";
  linkElement.type = "text/css";
  linkElement.href = cssFilePath;
  document.head.appendChild(linkElement);
};
