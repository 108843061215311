import { findCssValue } from "@/features/utils";
import tinycolor from "tinycolor2";
import { getAliasStyledType } from "@/features/builderHelper";

const labelAttach = (component: any, element: HTMLElement, schema: any) => {
  const labelElement = element?.querySelector("label");
  if (labelElement && schema?.designOptions) {
    labelElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.labelStyle;
  }
};

const inputAttach = (component: any, element: HTMLElement, schema: any) => {
  const attachInput = () => {
    const compElement = element.querySelector(
      `${
        component.type === "textarea" ? "textarea" : "input"
      }:not(.flatpickr-input)`
    );
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  };
  // on datetime type input add in DOM later
  component.type === "datetime" ? setTimeout(attachInput, 500) : attachInput();
};

const selectAttach = (component: any, element: HTMLElement, schema: any) => {
  let compElement;
  // looks like dropdown element added later
  setTimeout(() => {
    compElement = element?.querySelector(".selection.dropdown");
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  }, 0);
};

const datetimeAttach = (component: any, element: HTMLElement, schema: any) => {
  let compElement;
  let iconElement;
  // looks like dropdown element added later
  setTimeout(() => {
    compElement = element?.querySelector(".form-control.input");
    iconElement = element?.querySelector(".input-group-text");
    if (compElement && schema?.designOptions) {
      (compElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
    if (iconElement && schema?.designOptions) {
      (iconElement as HTMLElement).style.cssText =
        schema.designOptions[getAliasStyledType(component.type)]?.inputStyle;
    }
  }, 0);
};

const radioAttach = (component: any, element: HTMLElement, schema: any) => {
  const outerBoxes = element?.querySelectorAll(".custom-outer-box");
  const radioLabels = element?.querySelectorAll(".custom-radio-label span");
  const radioRowLabels = element?.querySelectorAll(".custom-control");
  if (outerBoxes && schema?.designOptions) {
    outerBoxes.forEach((item) => {
      const outerBoxStyle =
        schema.designOptions[getAliasStyledType(component.type)]
          ?.radioOuterBoxStyle;
      (item as HTMLElement).style.cssText = outerBoxStyle;
      (item as HTMLElement).style.height = findCssValue(
        "width",
        outerBoxStyle || "16px"
      );
      const innerBox = item.firstElementChild as HTMLElement;
      innerBox.style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.radioSelectedColor;
      innerBox.style.top = innerBox.style.left = `-${findCssValue(
        "border-width",
        outerBoxStyle || "-1px"
      )}`;
    });
  }
  if (radioLabels && schema?.designOptions) {
    radioLabels.forEach((item) => {
      (item as HTMLElement).style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.checkboxLabelStyle;
    });
  }
  if (radioRowLabels && schema?.designOptions) {
    radioRowLabels.forEach((item) => {
      (item as HTMLElement).style.cssText =
        schema.designOptions[
          getAliasStyledType(component.type)
        ]?.checkboxRowLabelStyle;
    });
  }
};

const placeholderAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  if (schema?.designOptions?.placeholderColor) {
    const rootVariables = document.querySelector(":root") as HTMLElement;
    rootVariables.style.setProperty(
      "--input-placeholder-color",
      findCssValue("color", schema.designOptions.placeholderColor)
    );
  }
};

const checkboxHoverAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  const rootVariables = document.querySelector(":root") as HTMLElement;
  if (schema?.designOptions?.checkboxHoverColor) {
    rootVariables.style.setProperty(
      "--checkbox-hover-color",
      findCssValue("color", schema.designOptions.checkboxHoverColor)
    );
  }
  if (schema?.designOptions?.radioHoverColor) {
    rootVariables.style.setProperty(
      "--radio-hover-color",
      findCssValue("color", schema.designOptions.radioHoverColor)
    );
  }
};

const descriptionAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  const descriptionElement = element?.querySelector(
    ".form-text.text-muted"
  ) as HTMLElement;
  if (descriptionElement && schema?.designOptions) {
    descriptionElement.style.cssText =
      schema.designOptions[
        getAliasStyledType(component.type)
      ]?.descriptionStyle;
  }
};

const dayLabelAttach = (component: any, element: HTMLElement, schema: any) => {
  const labelElements = element?.querySelectorAll("label");
  if (labelElements && schema?.designOptions) {
    labelElements.forEach((item: HTMLElement) => {
      item.style.cssText = schema.designOptions["day"]?.labelStyle;
    });
  }
};

const facebookButtonAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  if (
    schema?.designOptions &&
    schema.designOptions[getAliasStyledType(component.type)]
      ?.facebookButtonStyle
  ) {
    const rootVariables = document.querySelector(":root") as HTMLElement;
    const backgroundColor = findCssValue(
      "background-color",
      schema.designOptions[getAliasStyledType(component.type)]
        ?.facebookButtonStyle
    );
    rootVariables.style.setProperty(
      "--facebook-button-background",
      backgroundColor
    );
    rootVariables.style.setProperty(
      "--facebook-button-icon-background",
      tinycolor(backgroundColor).darken(15).toHexString()
    );
    rootVariables.style.setProperty(
      "--facebook-button-hovered-background",
      tinycolor(backgroundColor).darken(7).toHexString()
    );
    rootVariables.style.setProperty(
      "--facebook-button-border-color-top",
      tinycolor(backgroundColor).darken(6).toHexString()
    );
    rootVariables.style.setProperty(
      "--facebook-button-border-color-bottom",
      tinycolor(backgroundColor).darken(9).toHexString()
    );
    rootVariables.style.setProperty(
      "--facebook-button-border-color-side",
      tinycolor(backgroundColor).darken(12).toHexString()
    );
  }
};

const paddingAttach = (component: any, element: HTMLElement, schema: any) => {
  const paddingElement = element?.querySelector(".padding-component");
  if (paddingElement) {
    (paddingElement as HTMLElement).style.cssText = component.schema.height;
  }
};

const buttonAttach = (component: any, element: HTMLElement, schema: any) => {
  const buttonElement = element?.querySelector("button");
  if (buttonElement && schema?.designOptions) {
    buttonElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.buttonStyle;
  }
};

const buttonAlignAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  const container = element?.querySelector(".formio-component-button");
  const containerElement = container ? (container as HTMLElement) : element;
  if (containerElement && schema?.designOptions) {
    containerElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.buttonAlign;
  }
};

export const buttonHoverAttach = (
  component: any,
  element: HTMLElement | null,
  schema: any
) => {
  const rootVariables = document.querySelector(":root") as HTMLElement;
  if (schema?.designOptions?.buttonHoverColor) {
    rootVariables.style.setProperty(
      "--button-hover-color",
      findCssValue("color", schema.designOptions.buttonHoverColor)
    );
  }
  if (schema?.designOptions?.buttonBorderHoverColor) {
    rootVariables.style.setProperty(
      "--button-border-hover-color",
      findCssValue("color", schema.designOptions.buttonBorderHoverColor)
    );
  }
};

const confirmationLinkAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  if (
    schema?.designOptions &&
    schema.designOptions[getAliasStyledType(component.type)]?.confirmationStyle
  ) {
    const rootVariables = document.querySelector(":root") as HTMLElement;
    const color = findCssValue(
      "color",
      schema.designOptions[getAliasStyledType(component.type)]
        ?.confirmationStyle
    );
    rootVariables.style.setProperty("--confirmation-link-color", color);
    rootVariables.style.setProperty(
      "--confirmation-link-hover-color",
      tinycolor(color).darken(15).toHexString()
    );
  }
};

const customTexttAttach = (
  component: any,
  element: HTMLElement,
  schema: any
) => {
  const textElement = element?.querySelector(".custom-text-component");
  if (textElement && schema?.designOptions) {
    (textElement as HTMLElement).style.cssText =
      schema.designOptions[component.type]?.customTextStyle;
  }
};

const componentAttach = (component: any, element: HTMLElement, schema: any) => {
  const componentElement = element.classList.contains("formio-component")
    ? element
    : (element?.querySelector(".formio-component") as HTMLElement);
  if (componentElement && schema?.designOptions) {
    componentElement.style.cssText =
      schema.designOptions[getAliasStyledType(component.type)]?.componentStyle;
  }
};

export const componentsAttachList: Record<
  string,
  Array<(component: any, element: HTMLElement, schema: any) => void>
> = {
  input: [
    labelAttach,
    inputAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  url: [
    labelAttach,
    inputAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  checkbox: [
    labelAttach,
    radioAttach,
    descriptionAttach,
    checkboxHoverAttach,
    componentAttach,
  ],
  radio: [
    labelAttach,
    radioAttach,
    descriptionAttach,
    checkboxHoverAttach,
    componentAttach,
  ],
  confirmation: [
    radioAttach,
    checkboxHoverAttach,
    confirmationLinkAttach,
    componentAttach,
  ],
  select: [
    labelAttach,
    selectAttach,
    descriptionAttach,
    placeholderAttach,
    componentAttach,
  ],
  day: [dayLabelAttach, descriptionAttach, componentAttach],
  datetime: [labelAttach, datetimeAttach, descriptionAttach, componentAttach],
  button: [buttonAttach, buttonAlignAttach, buttonHoverAttach],
  facebookButton: [facebookButtonAttach],
  padding: [paddingAttach],
  customText: [customTexttAttach],
};
