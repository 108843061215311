import { Components } from "formiojs";
import { confirmationTemplate } from "@/formio-templates/confirmationTemplate";
const Component = (Components as any).components.component;
const CheckBoxComponent = (Components as any).components.checkbox;

export default class ConfirmationComponent extends (CheckBoxComponent as any) {
  static schema(): any {
    return CheckBoxComponent.schema({
      type: "confirmation",
      label: "Confirmation",
      key: "confirmation",
      confirmationText: "",
      linkText: "",
      linkUrl: "",
      validate: {
        required: true,
      },
    });
  }

  static builderInfo = {
    title: "Confirmation",
    icon: "check",
    key: "confirmation",
    schema: ConfirmationComponent.schema(),
  };

  get confirmLabel() {
    return this.schema.confirmationText.replace(
      "[link]",
      `<a href="${this.schema.linkUrl}" class="confirmation-link">${this.schema.linkText}</a>`
    );
  }

  render() {
    return new Component(this.component, this.options, this.data).render(
      confirmationTemplate(this)
    );
  }
}
