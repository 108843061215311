import { Formio } from "formiojs";
import { radioTemplate } from "@/formio-templates/radioTemplate";
import { singleCheckboxTemplate } from "@/formio-templates/singleCheckboxTemplate";
import FacebookButton from "@/formio-components/FacebookButton";
import PaddingComponent from "@/formio-components/PaddingComponent";
import ConfirmationComponent from "@/formio-components/ConfirmationComponent";
import { webformTemplate } from "@/formio-templates/webformTemplate";
import { wizardTemplate } from "@/formio-templates/wizardTemplate";
import { builderSidebarGroupTemplate } from "@/formio-templates/builderSidebarGroupTemplate";
import CustomTextComponent from "@/formio-components/CustomTextComponent";
import { builderWizardTemplate } from "@/formio-templates/builderWizardTemplate";
import { builderSidebarTemplate } from "@/formio-templates/builderSidebarTemplate";

export const useFormio = () => {
  // overwrite some formio templates
  Formio.Templates.current.radio.form = radioTemplate;
  Formio.Templates.current.checkbox.form = singleCheckboxTemplate;
  Formio.Templates.current.webform.form = webformTemplate;
  Formio.Templates.current.wizard.form = wizardTemplate;
  Formio.Templates.current.builderSidebar.form = builderSidebarTemplate;
  Formio.Templates.current.builderSidebarGroup.form =
    builderSidebarGroupTemplate;
  Formio.Templates.current.builderWizard.form = builderWizardTemplate;

  // add custom components
  Formio.use({
    components: {
      facebookButton: FacebookButton,
      padding: PaddingComponent,
      confirmation: ConfirmationComponent,
      customText: CustomTextComponent,
    },
  });

  return Formio;
};
